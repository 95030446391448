var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-page-container" },
    [
      _vm.params.loadingWebsocket
        ? _c(
            "div",
            { staticClass: "map-connecting" },
            [_c("a-spin", { attrs: { tip: _vm.params.loadingWebsocketMsg } })],
            1
          )
        : _vm._e(),
      _c(
        "baidu-map",
        {
          staticClass: "map-page",
          attrs: {
            center: _vm.center,
            zoom: _vm.zoom,
            "continuous-zoom": true,
            "scroll-wheel-zoom": true,
          },
          on: { ready: _vm.initMap },
        },
        [
          _c("bm-scale", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              offset: { width: 135, height: 15 },
            },
          }),
          _c("bm-map-type", {
            attrs: {
              "map-types": ["BMAP_NORMAL_MAP", "BMAP_HYBRID_MAP"],
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              offset: { width: 45, height: 15 },
            },
          }),
          _c("bm-navigation", {
            attrs: {
              anchor: "BMAP_ANCHOR_BOTTOM_RIGHT",
              type: "BMAP_NAVIGATION_CONTROL_ZOOM",
              "show-zoom-info": "true",
            },
          }),
          _vm.params
            ? _c(
                "bm-control",
                { staticClass: "map-control" },
                [
                  _vm.params.leftModalTitle
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-left-modal",
                          attrs: { type: "primary" },
                          on: { click: _vm.showLeftModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.params.leftModalTitle) + " ")]
                      )
                    : _vm._e(),
                  _vm.params.rightModalTitle
                    ? _c(
                        "a-button",
                        {
                          staticClass: "map-page-right-modal",
                          attrs: { type: "primary" },
                          on: { click: _vm.showRightModal },
                        },
                        [_vm._v(" " + _vm._s(_vm.params.rightModalTitle) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      staticClass: "map-page-distance",
                      on: { click: _vm.doSetDistance },
                    },
                    [_vm._v("测距")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.mapDataSource === "track"
            ? [
                _c(
                  "map-info-window",
                  {
                    attrs: {
                      position: _vm.trackInfo,
                      title: _vm.objectInfo[_vm.params.infoWindowTitle],
                      width: 380,
                      show: _vm.showTrackInfo,
                    },
                  },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "marker-card",
                        attrs: { title: "", bordered: false },
                      },
                      [
                        _c(_vm.currentTrackInfoWindowComponent.component, {
                          tag: "component",
                          attrs: {
                            "object-info": _vm.objectInfo,
                            "track-info": _vm.trackInfo,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.historyTrackPointsArray &&
                _vm.historyTrackPointsArray.length > 0
                  ? _c(
                      "div",
                      [
                        _c("b-map-polyline", {
                          key: _vm.index,
                          attrs: {
                            path: _vm.historyTrackPointsArray,
                            "stroke-color": _vm.routeLineColor,
                            "stroke-opacity": 0.5,
                            "stroke-weight": 3,
                            editing: false,
                            icons: _vm.icons,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handlePolylineClick($event)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : [
                _c(
                  "bml-marker-clusterer",
                  { attrs: { "average-center": true } },
                  _vm._l(_vm.currentObjectPoints, function (item, index) {
                    return _c("bm-marker", {
                      key: index,
                      attrs: {
                        position: item,
                        icon: _vm.params.objectIcon,
                        rotation: item.rotation,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleObjectClick($event, item)
                        },
                      },
                    })
                  }),
                  1
                ),
                _c(
                  "map-info-window",
                  {
                    attrs: {
                      position: _vm.currentObjectPoint,
                      title: _vm.objectInfo[_vm.params.infoWindowTitle],
                      show: _vm.showObjectInfo,
                      width: 380,
                    },
                    on: {
                      close: _vm.objectInfoWindowClose,
                      open: _vm.objectInfoWindowOpen,
                    },
                  },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "marker-card",
                        attrs: { title: "", bordered: false },
                      },
                      [
                        _c(_vm.currentObjectInfoWindowComponent.component, {
                          tag: "component",
                          attrs: { "object-info": _vm.objectInfo },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(_vm.params.laneDataArray, function (item) {
                  return _c(
                    "div",
                    { key: item.id },
                    [
                      _c("b-map-polyline", {
                        attrs: {
                          path: item.baiduPoints,
                          "stroke-color": _vm.routeLineColor,
                          "stroke-opacity": 0.5,
                          "stroke-weight": 2,
                          editing: false,
                          icons: _vm.icons,
                        },
                        on: { click: _vm.handlePolylineClick },
                      }),
                    ],
                    1
                  )
                }),
              ],
        ],
        2
      ),
      _vm.showMapTrackDetail
        ? _c("MapTrackDetail", {
            attrs: { historyTrackPointsArray: _vm.historyTrackPointsArray },
            on: {
              onTrackRowClick: _vm.handleTrackRowclick,
              onClose: _vm.handleMapTrackDetailClose,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }