<template>
  <!--  百度地图API2.0版本 -->
  <div class="map-page-container">
    <div class="map-connecting" v-if="params.loadingWebsocket"><a-spin :tip="params.loadingWebsocketMsg" /></div>
    <!-- 查看地图上物体的实时状态 -->
    <baidu-map class="map-page" :center="center" :zoom="zoom" @ready="initMap" :continuous-zoom="true" :scroll-wheel-zoom="true">
      <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 135, height: 15 }" />
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 45, height: 15 }" />
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_ZOOM" show-zoom-info="true" />
      <!-- 两侧操作按钮 -->
      <bm-control class="map-control" v-if="params">
        <!-- 左侧弹窗 -->
        <a-button v-if="params.leftModalTitle" type="primary" @click="showLeftModal" class="map-page-left-modal">
          {{ params.leftModalTitle }}
        </a-button>
        <!-- 右侧弹窗 -->
        <a-button v-if="params.rightModalTitle" type="primary" @click="showRightModal" class="map-page-right-modal">
          {{ params.rightModalTitle }}
        </a-button>
        <a-button class="map-page-distance" @click="doSetDistance">测距</a-button>
      </bm-control>
      <template v-if="mapDataSource === 'track'">
        <!-- 轨迹播放页地图上显示内容 -->
        <!-- 轨迹信息显示 -->
        <map-info-window :position="trackInfo" :title="objectInfo[params.infoWindowTitle]" :width="380" :show="showTrackInfo">
          <a-card title="" :bordered="false" class="marker-card">
            <component :is="currentTrackInfoWindowComponent.component" :object-info="objectInfo" :track-info="trackInfo" />
          </a-card>
        </map-info-window>

        <!-- 查看车辆/保洁员（工牌）等历史轨迹---3.轨迹播放 -->
        <div v-if="historyTrackPointsArray && historyTrackPointsArray.length > 0">
          <!-- 绘制折线 -->
          <b-map-polyline
            :key="index"
            :path="historyTrackPointsArray"
            :stroke-color="routeLineColor"
            :stroke-opacity="0.5"
            :stroke-weight="3"
            :editing="false"
            :icons="icons"
            @click="handlePolylineClick($event)"
          />
          <!-- 当前轨迹播放：路书 -->
        </div>
      </template>
      <template v-else>
        <!-- 显示车辆/设施/保洁员（工牌）等当前所在位置---1.位置分布 -->
        <bml-marker-clusterer :average-center="true">
          <bm-marker
            v-for="(item, index) in currentObjectPoints"
            :position="item"
            :icon="params.objectIcon"
            :rotation="item.rotation"
            :key="index"
            @click="handleObjectClick($event, item)"
          ></bm-marker>
        </bml-marker-clusterer>

        <!-- 点击车辆/设施/保洁员（工牌）弹窗显示相关的信息---2.信息显示 -->
        <map-info-window :position="currentObjectPoint" :title="objectInfo[params.infoWindowTitle]" :show="showObjectInfo" :width="380" @close="objectInfoWindowClose" @open="objectInfoWindowOpen">
          <a-card title="" :bordered="false" class="marker-card">
            <component :is="currentObjectInfoWindowComponent.component" :object-info="objectInfo" />
          </a-card>
        </map-info-window>
        <!-- 路线规划 -->
        <div v-for="item in params.laneDataArray" :key="item.id">
          <b-map-polyline :path="item.baiduPoints" :stroke-color="routeLineColor" :stroke-opacity="0.5" :stroke-weight="2" :editing="false" :icons="icons" @click="handlePolylineClick" />
        </div>
      </template>
    </baidu-map>
    <!-- 轨迹明细 -->
    <MapTrackDetail :historyTrackPointsArray="historyTrackPointsArray" @onTrackRowClick="handleTrackRowclick" @onClose="handleMapTrackDetailClose" v-if="showMapTrackDetail" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BMapPolyline from '@/components/Map/BMapPolyline'
import MapInfoWindow from './MapInfoWindow'
import MapTrackDetail from './MapTrackDetail'
import { GetObjectInfoWindowConfig, GetTrackInfoWindowConfig } from '../config/infoWindowConfig'
// 地图组件基类
import mapbase from '@/components/Map/base/mapbase.js'
// 轨迹播放：路书
import TrackPlayerHelper from './TrackPlayHelper'

export default {
  name: 'MapPage',
  mixins: [mapbase, TrackPlayerHelper],
  components: {
    BMapPolyline,
    MapInfoWindow,
    MapTrackDetail
  },
  props: {
    // 地图组件入参
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // 实时状态
      center: { lng: 0, lat: 0 },
      zoom: 3,
      // 历史轨迹
      routeLineColor: 'red',
      currentObjectPoint: {},
      // 当前对象弹窗信息组件
      currentObjectInfoWindowComponent: {},
      // 当前轨迹弹窗信息组件
      currentTrackInfoWindowComponent: {},
      // 当前车辆/设施/保法员信息
      objectInfo: {},
      trackInfo: {},
      showObjectInfo: false,
      showTrackInfo: false,
      currentObjectPoints: this.params.objectPoints,
      icons: [
        {
          symbol: {
            path: 7,
            opts: {
              scale: 0.6, // 图标缩放大小
              strokeColor: 'green', // 设置矢量图标的线填充颜色
              strokeWeight: 2 // 设置线宽
            }
          },
          offset: '100%',
          repeat: '100%',
          fixedRotation: false
        }
      ],
      objectInfoWindowModules: [],
      trackInfoWindowModules: [],
      showMapTrackDetail: this.params.showMapTrackDetail,
      clickPoints: [],
      realShowObjectInfo: false,
      mapDataSource: 'real',
      historyTrackPointsArray: []
    }
  },
  watch: {
    // 监控轨迹数据发生变化
    'params.historyTrackPointsArray'(newVal, oldVal) {
      this.historyTrackPointsArray = newVal
      // 初始化历史轨迹播放
      if (this.params.mapDataSource === 'track') {
        if (newVal && newVal.length > 0) {
          this.initHistoryTrackPlay(newVal)
        }
      }
    },
    // 物体所在位置
    // b-marker状态更新有问题，todo...
    'params.objectPoints'(newVal, oldVal) {
      if (newVal.length > 0) {
        // this.center.lng = newVal[0].lng
        // this.center.lat = newVal[0].lat
        console.log('objectPoints变化')
      }
      this.currentObjectPoints = newVal
      if (this.mapDataSource === 'track') {
        return
      }
      // 当对象列表在减少时，要校验当前弹窗对象是否还存在
      // 如果当前对象列表中不包含当前弹窗的对象，则关闭弹窗
      // 待验证 todo...
      if (this.showObjectInfo && oldVal && newVal.length < oldVal.length) {
        const infoWindowTitle = this.params.infoWindowTitle
        const tmpArr = this.currentObjectPoints.filter((p) => p[infoWindowTitle] === this.objectInfo[infoWindowTitle])
        if (tmpArr.length === 0) {
          this.showObjectInfo = false
        }
      }
      // 根据提供的地理区域或坐标设置地图视野，调整后的视野会保证包含提供的地理区域或坐标
      this.resetMapViewPort(newVal, oldVal)
    },
    'params.currentObject'(newVal, oldVal) {
      console.log('currentObject 发生变化', newVal)
      this.currentObjectPoint = newVal
      if (this.mapDataSource === 'track' && newVal) {
        this.map && this.map.setViewport([this.currentObjectPoint])
        return
      }

      if (this.showObjectInfo && newVal) {
        this.toShowInfo(newVal)
        this.resetCurrentObjectMapLocation(newVal, oldVal)
      }
    },

    showObjectInfo(newVal, oldVal) {
      console.log('关闭弹窗', newVal)
    },
    'params.showMapTrackDetail'(newVal, oldVal) {
      this.showMapTrackDetail = newVal
    },
    // 地图数据源
    'params.mapDataSource'(newVal, oldVal) {
      if (newVal === 'track') {
        // 轨迹播放
        this.realShowObjectInfo = this.showObjectInfo
        this.showObjectInfo = false
      } else {
        // 清除覆盖物
        this.map && this.map.clearOverlays()
        this.showObjectInfo = this.realShowObjectInfo
        this.showMapTrackDetail = false
      }
      if (oldVal === 'track' && newVal === 'real') {
        this.map && this.map.setViewport(this.currentObjectPoints)
      }
      setTimeout(() => {
        this.mapDataSource = newVal
      }, 50)
    },
    'params.laneDataArray'(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        const baiduPoints = newVal[0].baiduPoints
        if (baiduPoints.length > 0) {
          if (this.center.lng === baiduPoints[0].lng && this.center.lat === baiduPoints[0].lat) {
            this.center = {}
            setTimeout(() => {
              this.center = baiduPoints[0]
            }, 100)
          } else {
            this.center = baiduPoints[0]
          }
        }
      }
    }
  },
  created() {
    this.loadScript()
    this.setDefaultParams()
  },
  mounted() {
    console.log('mapPageParams', this.mapPageParams)
  },
  computed: {
    ...mapState({
      mapTheme: (state) => state.user.mapTheme,
      mapStyleJson: (state) => state.user.mapStyleJson
    }),
    ...mapGetters(['userTenant'])
  },
  methods: {
    // 重置当前对象在地图的位置
    // 如果地图坐标集不停发生变化，同时当前车辆的坐标也在变化，将会出现不停的切换视野与移动位置
    // 约定： 1. 当没有选中对象时，只要坐标集发生变化就重置视图
    //        2. 当有选中对象时：
    //                         2.1 只有当对象列表中的objectId发生变化时才重置视图
    //                         2.2
    resetCurrentObjectMapLocation(newVal, oldVal) {
      if (newVal && oldVal && newVal.lat === oldVal.lat && newVal.lng === oldVal.lng) {
        return
      }
      setTimeout(() => {
        this.map.panTo(new this.BMap.Point(newVal.lng, newVal.lat))
      }, 200)
    },
    // 重置地图视野
    resetMapViewPort(newVal, oldVal) {
      // 如果新旧坐标集没发生变化，则不重置地图视野
      if (newVal && oldVal && newVal.length === oldVal.length && newVal.length > 0) {
        const newArr = newVal.map((p) => {
          return p.lat + ',' + p.lng
        })
        const oldArr = oldVal.map((p) => {
          return p.lat + ',' + p.lng
        })
        if (newArr.toString() === oldArr.toString()) {
          return
        }
      }

      let isResetMap = false
      if (!this.params.currentObject) {
        // 当没选中对象时
        isResetMap = true
      } else {
        // 当对象id发生变化了
        if (newVal && oldVal && newVal.length === oldVal.length && newVal.length > 0) {
          const newIds = newVal.map((p) => {
            return p[this.params.objectId]
          })
          const oldIds = oldVal.map((p) => {
            return p[this.params.objectId]
          })
          const nids = newIds.toString()
          const oids = oldIds.toString()
          if (nids !== oids) {
            isResetMap = true
          }
        } else {
          isResetMap = true
        }
      }
      if (isResetMap) {
        console.log('setViewport')
        this.map && this.map.setViewport(this.currentObjectPoints)
      }
    },
    objectInfoWindowClose() {
      // 关闭对象信息窗口
      console.log('objectInfoWindowClose')
      this.showObjectInfo = false
    },
    objectInfoWindowOpen() {
      // 打开对象信息窗口
      console.log('objectInfoWindowOpen')
      this.showObjectInfo = true
    },
    handleTrackRowclick(record) {
      this.trackInfo = { ...record }
      this.center.lng = this.trackInfo.lng
      this.center.lat = this.trackInfo.lat
      const { myGeo, BMap } = this
      // 根据坐标得到地址描述
      myGeo.getLocation(new BMap.Point(record.lng, record.lat), (result) => {
        if (result) {
          result.address = result.address + '附近'
          this.trackInfo = { ...record, ...result }
        }
      })
      this.showTrackInfo = true
    },
    handleMapTrackDetailClose() {
      this.showTrackInfo = false
      this.$emit('mapPageClick', 'showMapTrackDetail', false)
    },
    addCss(url) {
      const $css = document.createElement('link')
      $css.setAttribute('rel', 'stylesheet')
      $css.setAttribute('href', url)
      global.document.body.appendChild($css)
    },
    addScript(url) {
      const $script = document.createElement('script')
      global.document.body.appendChild($script)
      $script.src = url
    },
    loadScript() {
      // 为什么加载会失败？ todo...
      // this.addCss('http://api.map.baidu.com/library/TrafficControl/1.4/src/TrafficControl_min.css')
      // 路况
      this.addScript('//api.map.baidu.com/library/TrafficControl/1.4/src/TrafficControl_min.js')
      // 测距
      this.addScript('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
    },
    // 初始化路况
    initTrafficControl(map) {
      var ctrl = new window.BMapLib.TrafficControl({
        showPanel: false // 是否显示路况提示面板
      })
      map.addControl(ctrl)
      ctrl.setAnchor(window.BMAP_ANCHOR_TOP_RIGHT)
    },
    // 初始化测距工具
    initDistanceTool(map) {
      this.myDis = new window.BMapLib.DistanceTool(map)
    },
    setDefaultParams() {
      // 对象信息
      this.objectInfoWindowModules = GetObjectInfoWindowConfig()[0].modules
      // 如果提示组件已设置，则不用重新设置，待验证todo...
      if (!this.currentObjectInfoWindowComponent.component) {
        const tmpArr = this.objectInfoWindowModules.filter((p) => p.id === this.params.moduleKey)
        if (tmpArr.length === 1) {
          console.log('找到对象弹窗信息组件配置', this.params.moduleKey)
          this.currentObjectInfoWindowComponent = tmpArr[0]
        } else {
          console.log('找不对象弹窗信息组件配置', this.params.moduleKey)
          this.currentObjectInfoWindowComponent = {}
        }
      }

      // 轨迹信息
      this.trackInfoWindowModules = GetTrackInfoWindowConfig()[0].modules
      if (!this.currentTrackInfoWindowComponent.component) {
        const tmpArr = this.trackInfoWindowModules.filter((p) => p.id === this.params.moduleKey)
        if (tmpArr.length === 1) {
          console.log('找到轨迹弹窗信息组件配置', this.params.moduleKey)
          this.currentTrackInfoWindowComponent = tmpArr[0]
        } else {
          console.log('找不轨迹弹窗信息组件配置', this.params.moduleKey)
          this.currentTrackInfoWindowComponent = {}
        }
      }

      // 轨迹播放路书配置
      this.lushuConfig = this.params.lushuConfig
    },

    initMap(MapObj) {
      this.BMap = MapObj.BMap
      this.map = MapObj.map
      // 返回对象实时地图实例对象
      this.$emit('mapPageClick', 'setMapObj', this.map)
      this.myGeo = new this.BMap.Geocoder()
      console.log('this.mapTheme', this.mapTheme)
      if (this.mapStyleJson) {
        // 地图自定义样式
        this.map.setMapStyle({
          styleJson: this.mapStyleJson
        })
      } else {
        // 地图样式主题
        this.mapTheme && this.map.setMapStyle({ style: this.mapTheme })
      }
      // 获取当前租户配置的默认坐标
      this.center.lng = this.userTenant.lng
      this.center.lat = this.userTenant.lat

      this.zoom = 18
      this.bindMapEvent()

      setTimeout(() => {
        this.initTrafficControl(this.map)
        this.initDistanceTool(this.map)
      }, 1000)

      this.map.addEventListener('click', (e) => {
        this.clickPoints.push(e.point)
        console.log('trackPoints', JSON.stringify(this.clickPoints))
      })
    },

    bindMapEvent() {},
    showLeftModal() {
      this.$emit('mapPageClick', 'showLeftModal', true)
    },
    showRightModal() {
      this.$emit('mapPageClick', 'showRightModal', true)
    },
    // 手动点击弹窗
    handleObjectClick(e, item) {
      this.currentObjectPoint = e.point
      console.log('handleObjectClick')
      // this.$emit('mapPageClick', 'selectObjectInfo', item)
      this.toShowInfo(item)
    },
    toShowInfo(item) {
      const { myGeo, BMap } = this
      // 根据坐标得到地址描述
      myGeo.getLocation(new BMap.Point(item.lng, item.lat), (result) => {
        if (result) {
          result.address = result.address + '附近'
          this.objectInfo = { ...item, ...result }
        }
        this.$emit('mapPageClick', 'selectObjectInfo', { ...this.objectInfo })
      })
      this.showObjectInfo = true
    },
    handleHistoryTrackClick(e, strokeColor) {},
    // 开启测距
    doSetDistance() {
      this.myDis.open()
    },
    // 弹窗显示物体信息，如果selectedId不为空，则显示，为空则不显示
    mapShowObjectInfo(selectedId) {
      if (this.params.mapDataSource === 'track') {
        this.showObjectInfo = false
        return
      }
      if (selectedId) {
        this.showObjectInfo = true
      } else {
        this.showObjectInfo = false
      }
    }
  }
}
</script>

<style lang="less">
.map-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  .map-connecting {
    position: absolute;
    margin: auto;
    top: 0px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1;
    .ant-spin-text {
      color: @primary-font-color;
    }
  }
  .my-modal {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 6px 6px !important;
    }
    .vxe-modal--content {
      padding: 0 1em !important;
    }
  }

  .map-page {
    height: 100%;
    .map-page-left-modal {
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .map-page-right-modal {
      position: absolute;
      top: 10px;
      right: 160px;
    }
    .map-page-distance {
      position: absolute;
      top: 10px;
      right: 90px;
    }
    .map-control {
      position: relative !important;
    }
    .ant-btn {
      height: 22px !important;
      border-radius: 1px !important;
    }
    .container {
      display: flex;
      img {
        width: 100px;
        height: 100px;
        min-width: 100px;
      }
      .item {
        display: flex;
        justify-content: space-between;
        padding: 0;
        // width: 300px;
      }
      .right {
        // padding-left: 20px;
      }
    }
    .marker-card {
      // min-width: 380px;
      // margin-top: 18px;
      border-top: 1px solid #d9d9d9;
      .ant-card-body {
        padding: 5px;
      }
    }
  }
}

// 以下是路况信息的样式，因远程加载失败
.maplibTc {
  font-size: 12px;
  width: 243px;
  border: 1px solid #8ba4d8;
  padding: 3px 2px 0 5px;
  background: #fff;
  position: absolute;
}
.maplibTc a {
  text-decoration: none;
}
.maplibTcColor {
  background: url('http://api.map.baidu.com/images/tools_menu.gif') no-repeat scroll 138px -85px transparent;
  font-weight: bold;
  *background-position: 128px -85px;
}
.maplibTcUpdate {
  float: left;
  width: 13px;
  height: 14px;
  background: url(http://api.map.baidu.com/images/tools_menu.gif) no-repeat -12px -18px;
  margin-left: 5px;
  cursor: pointer;
}
.maplibTcView {
  float: right;
  color: #00f;
  text-decoration: none;
  line-height: 15px;
  *line-height: 18px;
}
.maplibTcCurTime {
  float: left;
  color: #666;
}
.maplibTcTime {
  height: 20px;
  padding: 5px 3px 0 0;
}
.maplibTcWeekDay {
  height: 22px;
  color: #6688ca;
  padding: 3px 0;
}
.maplibTcWeekDay a {
  color: #6688ca;
  padding: 3px 2px;
}
.maplibTcWeekDay ul {
  float: left;
  margin: 0;
  padding: 0;
}
.maplibTcWeekDay span {
  float: left;
  line-height: 23px;
}
.maplibTcWeekDay li {
  float: left;
  padding: 0 6px;
  list-style: none;
  line-height: 23px;
}
.maplibTcRule {
  background: url('http://api.map.baidu.com/images/bar.gif') no-repeat scroll 0 10px transparent;
  width: 195px;
  float: left;
  margin-left: 20px;
  *margin-left: 10px;
}
.maplibTcRuleTxt {
  float: left;
  line-height: 44px;
}
.maplibTcClear {
  clear: both;
}
.maplibTcTimeBox {
  color: #6688ca;
  margin-left: 137.5px;
  font-size: 11px;
  overflow: hidden;
}
.maplibTcTimeline {
  height: 34px;
}
.maplibTcTimelinePrev {
  overflow: hidden;
  width: 9px;
  height: 9px;
  cursor: pointer;
  float: left;
  margin-top: 3px;
}
.maplibTcTimelineNext {
  overflow: hidden;
  width: 11px;
  *width: 10px;
  height: 9px;
  cursor: pointer;
  float: right;
  margin-top: 3px;
}
.maplibTcTimeMove {
  width: 9px;
  height: 18px;
  background: url('http://api.map.baidu.com/images/tools_menu.gif') no-repeat scroll 0 -32px transparent;
  float: left;
  cursor: pointer;
  margin-left: 137.5px;
  margin-top: 0;
}
.maplibTcHide {
  display: none;
}
.maplibTcBtn_deskTop {
  background: url(http://api.map.baidu.com/images/bgs.gif) no-repeat scroll 0 -271px transparent;
  cursor: pointer;
  height: 22px;
  width: 73px;
  z-index: 10;
  position: absolute;
}
.maplibTcBtn_mobile {
  background: url(http://api.map.baidu.com/images/traffic_bgs.png) rgba(255, 255, 255, 0.8) no-repeat scroll -30px 0;
  border: 1px solid #afafaf;
  background-size: 60px 30px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  z-index: 10;
  position: absolute;
}
.maplibTcBtn_deskTop {
  background-position: 0 -249px;
}
.maplibTcBtnOff_mobile {
  background-position: 0 0;
}
.maplibTcColon {
  float: left;
}
.maplibTcOn {
  background: #e6eff8;
}
.maplibTcClose {
  background: url('http://api.map.baidu.com/images/popup_close.gif') no-repeat scroll 0 0 transparent;
  border: 0 none;
  cursor: pointer;
  height: 12px;
  position: absolute;
  right: 4px;
  top: 5px;
  width: 12px;
}
.maplibTfctr {
  min-width: 9em;
  height: 2.2em;
  display: -webkit-box;
  -webkit-box-align: center;
  -webkit-border-radius: 0.3em;
  border: 0.1em solid #989898;
  -webkit-box-sizing: border-box;
  background-color: #fff;
  font-size: 14px;
}
.maplibTfctrHide {
  display: none;
}
.maplibTfctr_c {
  -webkit-box-flex: 1;
}
.maplibTfctr_status {
  width: 4em;
  margin-right: 0.45em;
}
.maplibTfctr_status span {
  display: inline-block;
  margin-left: 0.3em;
  font-size: 14px;
}
.maplibTfctr div,
.maplibTfctr span {
  -webkit-box-sizing: border-box;
}
.maplibTfctr_l {
  margin: 0 0.15em;
}
.maplibY {
  background: #ffae00;
}
.maplibR {
  background: #f00;
}
.maplibG {
  background: #1fba00;
}
// 以上是路况信息的样式，因远程加载失败
</style>
